export const APP_NAME = process.env.NEXT_PUBLIC_APP_NAME || 'Arogga';
export const TEL_NUMBER = process.env.NEXT_PUBLIC_TEL_NUMBER || '09610016778';

// Following must set in .env.local file
export const APP_URL = process.env.NEXT_PUBLIC_APP_URL ? process.env.NEXT_PUBLIC_APP_URL : 'https://m.arogga.com';
export const MAIN_WEB_URL = process.env.NEXT_PUBLIC_MAIN_WEB_URL || 'https://www.arogga.com';
export const SECOND_WEB_URL = process.env.NEXT_PUBLIC_SECOND_WEB_URL || 'https://m.arogga.com';
export const API_BASE_URL = process.env.NEXT_PUBLIC_API_BASE_URL || 'https://api.arogga.com';
export const ASSET_CDN_URL = process.env.NEXT_PUBLIC_ASSET_CDN_URL;
export const SITE_MAP_API = process.env.NEXT_SITEMAP_API_KEY;
export const IS_PRODUCTION = process.env.NEXT_PUBLIC_IS_PRODUCTION;
export const PIXELID = process.env.NEXT_PUBLIC_PIXELID || '';
export const MIXPANEL_TOKEN = process.env.NEXT_PUBLIC_MIXPANEL_PROJECT_TOKEN || '';
export const LT_BASE_URL = process.env.NEXT_PUBLIC_LT_BASE_URL || 'https://lab.arogga.com';
export const userStorageName = 'arogga_user_info';
export const cartStorageName = 'arogga_cart_info';
export const lastOrderFromCartStorageName = 'arogga_last_order_from_cart';
export const isCouponExpireStorageName = 'arogga_cart_coupon_expire';
export const cartBseStorageName = 'arogga_bse_cart_info';
export const userDefaultLocation = 'user_default_location';
export const userSearchHistory = 'arogga_user_search_history';
export const userPrevSearch = 'arogga_user_prev_search';
export const refPartnerApiStorageName = 'refPartnerApiInfo';

export const L_CALL_NUMBER = '+8809610016778';
export const L_CALL_CENTER = '16778';
export const L_MESSENGER = 'https://m.me/112199680191257';

//Refer
export const APP_DOWNLOAD_LINK_ANDROID = 'https://play.google.com/store/apps/details?id=com.arogga.app';
export const APP_DOWNLOAD_LINK_ISO = 'https://apps.apple.com/us/app/arogga/id1499378347';

//height
export const FOOTER_HEIGHT = '80px';
