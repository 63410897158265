// 'use client';
import Image, { StaticImageData } from 'next/image';
import notFound from '@/services/utils/placeholder/Undefine_image.png';
import styles from './styles/img.module.scss';
import { TruncatedContent } from '@/services/utils/cmnHelper';

//placeholder
import beauty from '@/services/utils/placeholder/Beauty.png';
import food from '@/services/utils/placeholder/Food.png';
import healthcare from '@/services/utils/placeholder/Healthcare.png';
import medicine from '@/services/utils/placeholder/medicine.png';
import pet_vet from '@/services/utils/placeholder/Pet & vet.png';

interface Props {
    alt?: string;
    src: string | StaticImageData | undefined;
}

const generateDefaultImg = (placeholder: any) => {
    if (placeholder?.type === 'beauty' || placeholder?.type === 'cosmetics') {
        return beauty;
    } else if (placeholder?.type === 'food') {
        return food;
    } else if (placeholder?.type === 'pet_&_vet') {
        return pet_vet;
    } else if (placeholder?.type === 'medicine') {
        return medicine;
    } else if (placeholder?.type === 'health_care' || placeholder?.type === 'healthcare') {
        return healthcare;
    } else {
        return notFound;
    }
};
// const imageLoader = ({ src }: any) => {
//     return src;
// };

const Img: React.FC<Props> = ({ alt, src }) => (
    <div className={`${styles.img_wrap}`}>
        <Image
            alt={(alt && TruncatedContent({ content: alt, maxCharacters: 80 })) || 'arogga'}
            // loader={imageLoader}
            src={src || notFound}
            // fill
            // sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
            // width={100}
            // height={100}
            fill
            unoptimized
            priority={true}
            quality={100}
        />
    </div>
);

export default Img;
