import _ from 'lodash';
import { cartBseStorageName, userStorageName } from '../apis/config';
import { BlockLinkParameterType } from '../types/blocks-type';
import { ProductTypes, variantType } from '../types/product-types';
import { R_PRODUCT } from './path';

export const calculatePercentage = (d_price: number, price: number) => {
    if (d_price && price) {
        const percentage = ((d_price - price) / d_price) * 100;
        return percentage.toFixed(0);
    } else {
        return 0;
    }
};

export function validPhoneNumber(phone: string) {
    const regex = /(^(\+8801|8801|008801|01))(\d){9}$/;
    return regex.test(phone);
}
export const validEmail = (email: string) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
};

export const textToSlug = (text: string): string =>
    text
        ?.toString()
        ?.trim()
        ?.toLowerCase()
        ?.replace(/\s+/g, '-')
        ?.replace(/[^\w\-]+/g, '')
        ?.replace(/\-\-+/g, '-')
        ?.replace(/^-+/, '')
        ?.replace(/-+$/, '');

export const titleToSlug = (product: ProductTypes): string => {
    const text = `${product.p_name || ''}-${product.p_form || ''}-${product.p_strength || ''}`;
    return textToSlug(text);
};

export const capitalizeFirstLetter = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
};

export const generateProductLink = (product: any, varId: number, idKey?: any) => {
    return product ? `${R_PRODUCT}/${idKey ? product[idKey] : product?.p_id}/${titleToSlug(product)}?pv_id=${varId}` : '/';
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const setLocalData = (data: any, type: 'user' | 'cart') => {
    if (typeof window !== 'undefined') {
        if (type === 'cart') {
            localStorage.setItem(cartBseStorageName, JSON.stringify(data));
        } else if (type === 'user') {
            localStorage.setItem(userStorageName, JSON.stringify(data));
        }
    } else {
        console.log('Window Not Found!');
    }
};

export const getRandomElement = <T>(array: T[]): T | undefined => {
    const randomIndex = Math.floor(Math.random() * array?.length);
    return array[randomIndex];
};

export const filterByIsBase = (arr: variantType[], pvId = NaN, onlyByPvID = false) => {
    if (Array.isArray(arr)) {
        const foundById = arr?.find((d: variantType) => d.pv_id === pvId);

        if (foundById !== undefined) {
            return foundById;
        }
        if (onlyByPvID) {
            return foundById;
        }
        const foundElement = arr?.find((d: variantType) => !!d.pv_is_base);

        if (foundElement !== undefined) {
            return foundElement;
        } else if (arr.length > 0) {
            return arr[0];
        } else {
            return null;
        }
    } else {
        return arr;
    }
};

export const hexToRgba = (hex: string, alpha: number, isHasColor?: string) => {
    hex = hex.replace(/^#/, '');

    const validHexRegex = /^(?:[0-9a-fA-F]{3}){1,2}$|^(?:[0-9a-fA-F]{4}){1,2}$|^([0-9a-fA-F]{6}|[0-9a-fA-F]{8})$/;
    if (!validHexRegex.test(hex)) {
        return isHasColor ? isHasColor : `#FFF`;
    }

    const digitCount = hex.length;
    let r, g, b;

    if (digitCount === 3) {
        r = parseInt(hex[0] + hex[0], 16);
        g = parseInt(hex[1] + hex[1], 16);
        b = parseInt(hex[2] + hex[2], 16);
    } else if (digitCount === 4) {
        r = parseInt(hex[0] + hex[0], 16);
        g = parseInt(hex[1] + hex[1], 16);
        b = parseInt(hex[2] + hex[2], 16);
        alpha = parseInt(hex[3] + hex[3], 16) / 255;
    } else if (digitCount === 6) {
        r = parseInt(hex.slice(0, 2), 16);
        g = parseInt(hex.slice(2, 4), 16);
        b = parseInt(hex.slice(4, 6), 16);
    } else if (digitCount === 8) {
        r = parseInt(hex.slice(0, 2), 16);
        g = parseInt(hex.slice(2, 4), 16);
        b = parseInt(hex.slice(4, 6), 16);
        alpha = parseInt(hex.slice(6, 8), 16) / 255;
    } else {
        return `rgba(255, 0, 0, ${alpha})`;
    }

    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

export const getMonthAndDay = (dateString: string, years = false, single = false) => {
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const [year, month, day] = dateString?.split('-');
    const monthIndex = parseInt(month, 10) - 1;
    const abbreviatedMonth = months[monthIndex];
    const covertMonthDay = `${abbreviatedMonth} ${parseInt(day, 10)}`;
    const covertMonthDayYear = `${abbreviatedMonth} ${parseInt(day, 10)} ${year}`;
    const covertMonthDayYearSingle = `${abbreviatedMonth}-${parseInt(day, 10)}-${year}`;

    if (years) {
        if (single) {
            return covertMonthDayYearSingle;
        }
        return abbreviatedMonth ? covertMonthDayYear : dateString;
    }
    return abbreviatedMonth ? covertMonthDay : dateString;
};
// export const convertTime = (timeString: string, onlyTime = false) => {
//     const [date, time] = timeString?.split(' ');
//     const [hours, minutes] = onlyTime ? timeString?.split(':') : time?.split(':');
//     const parsedHours = parseInt(hours, 10);
//     const period = parsedHours >= 12 ? 'PM' : 'AM';
//     const formattedHours = parsedHours % 12 || 12;

//     return `${formattedHours}:${minutes} ${period}`;
// };

export const convertTime = (timeString: string, onlyTime = false) => {
    const [date, time] = timeString?.split(' ');

    const [hours, minutes] = onlyTime ? timeString?.split(':') : time?.split(':');
    const parsedHours = parseInt(hours, 10);
    const period = parsedHours >= 12 ? 'PM' : 'AM';
    const formattedHours = parsedHours % 12 || 12;

    return `${formattedHours}:${minutes} ${period}`;
};

export const formatNumberToK = (number: number) => {
    if (number < 1000) {
        return number;
    } else if (number < 1000000) {
        const rounded = Math.floor(number / 100) / 10;
        return `${rounded}k`;
    } else {
        return `1M+`;
    }
};

export const createUrlParameters = (params: BlockLinkParameterType[], base: string) => {
    if (!params) {
        if (base) {
            return base;
        } else return undefined;
    }
    const query = Object.keys(params)
        .map((key: any) => `${key}=${params[key]}`)
        .join('&');
    const apiUrl = base ? `${base}?${query}` : query;
    return apiUrl.startsWith('/') ? apiUrl.substring(1) : apiUrl;
};

export const createQueryString = (params: Record<string, string | number>, exclude: string) => {
    if (exclude) {
        return Object.entries(params)
            .map(([key, value]) => (key === exclude ? `${value}` : `${key}=${value}`))
            .join('&');
    } else {
        return Object.entries(params)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');
    }
};

export const objectToQueryString = (obj: any) => {
    const queryString = Object.keys(obj)
        .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`)
        .join('&');
    return queryString;
};

export const queryStringToObject = (queryString: any) => {
    const params: any = new URLSearchParams(queryString);
    const result: any = {};

    for (const [key, value] of params.entries()) {
        result[key] = value;
    }

    return result;
};

export const removeQueryParam = (url: string) => {
    return url.replace(/\?.*$/, '');
};
export const getQueryParams = (url: string): string => {
    const match = url.match(/\?(.*)$/);
    return match ? match[1] : '';
};
export const removeQueryParamByName = (url: string, paramName: string) => {
    const searchParams = new URLSearchParams(url);
    searchParams.delete(paramName);
    const updatedQueryString = searchParams.toString();
    return updatedQueryString;
};

export const convertFileToBase64 = (file: File) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = () => {
            resolve(reader.result);
        };

        reader.onerror = (error) => {
            reject(error);
        };

        reader.readAsDataURL(file);
    });
};

export const base64ToBlob = (base64Image: any, type = 'image/png') => {
    const [, data] = base64Image.split(',');
    const byteCharacters = atob(data);
    const byteNumbers = new Uint8Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const blob = new Blob([byteNumbers], { type });
    return blob;
};

export const base64ToBinary = (base64String: string) => {
    const base64WithoutPrefix = base64String.replace(/^data:image\/png;base64,/, '');

    const binaryString = atob(base64WithoutPrefix);
    const length = binaryString.length;
    const bytes = new Uint8Array(length);

    for (let i = 0; i < length; i++) {
        bytes[i] = binaryString.charCodeAt(i);
    }

    return bytes;
};

export const TruncatedContent = ({ content, maxCharacters, tagsRemove }: any) => {
    const truncateContent = (text: any, maxLength: any) => {
        if (text?.length > maxLength) {
            return `${text.slice(0, maxLength)}...`;
        }
        return text;
    };

    const removeHTMLTags = (text: any) => {
        return text.replace(/<[^>]*>/g, '');
    };

    let truncatedContent = content;

    if (tagsRemove) {
        truncatedContent = removeHTMLTags(content);
    }

    truncatedContent = truncateContent(truncatedContent, maxCharacters);

    return truncatedContent;
};

export const findFirstValidSrc = (array: any) => {
    if (array?.length) {
        const validItem = array?.find((item: any) => item?.src);
        if (validItem) {
            return [validItem];
        } else {
            return false;
        }
    } else {
        return false;
    }
};

// const setQueryParamsAndDelete = (
//     setDelete: boolean,
//     pathName: string,
//     allQueryParams: string,
//     queryParamKey: string,
//     paramValue: string,
// ) => {
//     const urlSearchParams = new URLSearchParams(allQueryParams);

//     if (setDelete) {
//         urlSearchParams.set(queryParamKey, paramValue);
//     } else {
//         urlSearchParams.delete(queryParamKey);
//     }

//     const newPath = `${pathName}?${decodeURIComponent(urlSearchParams.toString())}`;
//     router.replace(newPath);
// };

// export const createCountdownTimer = (startDateString: string, endDateString: string) => {
//     let timerInterval: NodeJS.Timeout | null = null;

//     const startTimer = () => {
//         const startDate = new Date(startDateString).getTime();
//         const endDate = new Date(endDateString).getTime();

//         if (isNaN(startDate) || isNaN(endDate)) {
//             console.log('Invalid date format. Please provide valid date strings.');
//             return;
//         }

//         const calculateTimeRemaining = () => {
//             const now = new Date().getTime();
//             const timeRemaining = endDate - now;

//             const days = Math.floor(timeRemaining / (1000 * 60 * 60 * 24));
//             const hours = Math.floor((timeRemaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
//             const minutes = Math.floor((timeRemaining % (1000 * 60 * 60)) / (1000 * 60));
//             const seconds = Math.floor((timeRemaining % (1000 * 60)) / 1000);

//             return {
//                 days,
//                 hours,
//                 minutes,
//                 seconds
//             };
//         };

//         const updateTimer = () => {
//             const time = calculateTimeRemaining();

//             if (time.days <= 0 && time.hours <= 0 && time.minutes <= 0 && time.seconds <= 0) {
//                 clearInterval(timerInterval!);
//                 return 'Time expired!';
//             }

//             return `${time.days} days, ${time.hours} hours, ${time.minutes} minutes, ${time.seconds} sec left. hurry`;
//         };

//         timerInterval = setInterval(() => {
//             const currentTime = updateTimer();
//             console.log(currentTime);
//         }, 1000);

//         return updateTimer;
//     };

//     return startTimer();
// };

export const getFormattedDate = (isoTimestamp: any) => {
    if (!isoTimestamp) return 'N/A';

    const dateInstance = new Date(isoTimestamp);

    return new Intl.DateTimeFormat('en-US', { day: '2-digit', month: '2-digit', year: 'numeric' }).format(dateInstance);
};
export const removeUnderscore = (word: string, prefixes: string[] = []) => {
    let removedUnderscore: string = word;

    prefixes.forEach((prefix) => {
        if (removedUnderscore.startsWith(prefix)) {
            removedUnderscore = removedUnderscore.substring(prefix.length);
        }
    });

    removedUnderscore = removedUnderscore.split('_').join(' ');

    return removedUnderscore;
};

export const extractSearchValue = (url: string, params: any) => {
    const nextKeys = [
        '_product_type',
        '_order',
        '_brand_id',
        '_form',
        '_tags',
        '_min_pv_b2c_price',
        '_max_pv_b2c_price',
        '_min_pv_b2b_price',
        '_max_pv_b2b_price'
    ];

    const hasKeyInParams = nextKeys.some((key) => params.get(key));
    const nextKeysPattern = nextKeys.map((key) => `\\${key}`).join('|');
    const searchPattern = new RegExp(`(?<=_search=)[^&]+(&[^&]+)*?(?=(&(${nextKeysPattern})))`);
    const match = url.match(searchPattern);
    let searchValue = hasKeyInParams ? (match ? match[0] : '') : url;

    if (searchValue.endsWith('=')) {
        searchValue = searchValue.slice(0, -1);
    }
    if (searchValue.startsWith('_search=') && !hasKeyInParams) {
        searchValue = searchValue.slice('_search='.length);
    }

    searchValue = searchValue.replace(/\+=/g, ' ').replace(/\+/g, ' ');

    return searchValue.trim();
};

export const getFlatNumber = (num: number = 0) => {
    return num.toString().split('.')[0];
};

export const isDhakaCity = (l_district: string) => {
    return l_district === 'Dhaka City' ? true : false;
};


export const formatDateTimeAgo = (dateString: string): string => {
    if (!dateString || isNaN(new Date(dateString).getTime())) {
        return "Invalid date"; // Return a default message for empty or invalid dates
    }

    const inputDate = new Date(dateString);
    const now = new Date();
    const diffInSeconds = Math.floor((now.getTime() - inputDate.getTime()) / 1000);

    const intervals = {
        year: 31536000,
        month: 2592000,
        week: 604800,
        day: 86400,
        hour: 3600,
        minute: 60,
        second: 1,
    };

    for (const key in intervals) {
        const seconds = intervals[key as keyof typeof intervals];
        const count = Math.floor(diffInSeconds / seconds);
        if (count > 0) {
            return `${count} ${key}${count > 1 ? 's' : ''} ago`;
        }
    }

    return "just now";
};

export const convertToKFormat = (count: number): string => {
    if (count >= 1000) {
        return `${(count / 1000).toFixed(1)}k`; // Format as 1.0k
    }
    return count?.toString(); // Return as a string
};